<template>
    <div class="affiliate-form">
        <!-- <p class="wizard-payments-title">
            Bank Transfer:
        </p> -->
        <form class="affiliate-payments-form">
            <template v-for="input in fields">
                <form-input
                    v-if="$v.formFields[input.key]"
                    :key="input.key"
                    v-model="$v.formFields[input.key].$model"
                    :legend="input.title"
                    :field.sync="formFields[input.key]"
                    :errors="validationMessage($v.formFields[input.key])"
                    :is-valid="$v.formFields[input.key].$dirty && !$v.formFields[input.key].$anyError"
                    @input.native="$v.formFields[input.key].$touch()"
                    @blur.native="$v.formFields[input.key].$touch()"
                />
                <form-input
                    v-else
                    :key="input.key"
                    v-model="formFields[input.key]"
                    :legend="input.title"
                    :field.sync="formFields[input.key]"
                />
            </template>
        </form>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/form-validation-mixin.js'
// import { validationRules } from '@/validation/wizard/wizard-payment/wire/rules.js'
import { formMessages } from '@/validation/payment/wire/messages.js'
import { eventBus } from '@/helpers/event-bus';
import { debounce } from 'lodash'
import {
    required
    // minLength
    // requiredIf
} from 'vuelidate/lib/validators';

export default {
    name: 'AffiliatePaymentForm',
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        fields: {
            type: Array,
            required: true
        },
        formData: {
            type: Object
        }
    },
    validations() {
        const rules = {
            formFields: {}
        }
        this.fields.forEach((item) => {
            if (item.validation && item.validation.required) {
                this.$set(rules.formFields, item.key, {
                    required
                })
            }
        })
        return rules
    },
    data() {
        return {
            formFields: {}
        }
    },
    watch: {
        formFields: {
            handler: debounce(function (value) {
                this.$emit('update', value)
            }, 100),
            deep: true
        },
        fields: {
            handler: debounce(function () {
                this.formFields = {}
                this.fields.forEach(({ key }) => {
                    this.$set(this.formFields, key, '')
                })
            }, 100),
            deep: true
        }
    },
    created() {
        this.fields.forEach(({ key }) => {
            this.$set(this.formFields, key, '')
        })
        if (this.formData) {
            Object.keys(this.formFields).forEach((key) => {
                this.formFields[key] = this.formData?.[key] ? this.formData?.[key] : ''
            })
        }
    },
    mounted() {
        eventBus.$on('saveSettings', () => {
            this.validate()
        })
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    methods: {
        validationMessage: validationMessage(formMessages)
    }
}

</script>

<style lang="scss">
    .affiliate {
        &-form {
            .form-input__fieldset {
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 8px;
                }
            }
        }
        &-payments {
            &-title {
                font-size: 24px;
                margin-bottom: 20px;
            }
        }
    }
</style>
